import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useClientRender } from '../../hooks/useClientRender';
import Button from '../Button';

const HeaderFloatButton = ({ isSearchFull, setIsSearchFull, currentPosition }) => {
  const clickHandler = () => setIsSearchFull((state) => !state);
  const router = useRouter();
  const { isScreenType } = useClientRender();

  const pageType = router.pathname.split('/').filter((i) => i)[0];

  useEffect(() => {
    setIsSearchFull(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  const showButton = isScreenType.desktop && pageType === 'types';

  return showButton ? (
    <Button onClick={clickHandler} type="button" className="header__float-btn">
      {isSearchFull ? 'Все разделы' : currentPosition.nameRu}
    </Button>
  ) : null;
};

export default HeaderFloatButton;
