import cn from 'classnames';
import Link from 'next/link';

import ButtonContent from './ButtonContent';

const Button = ({
  label,
  className,
  disabled = false,
  onClick,
  color = 'blue',
  children,
  link,
  type,
  size = 'big',
}) => {
  const buttonClasses = cn('button', {
    [`${className}`]: className,
    'button--disabled': disabled,
    'button--big': size === 'big',
    'button--small': size === 'small',
    [`button--${color}`]: color,
  });

  return link && !disabled ? (
    <Link href={link}>
      <a className={buttonClasses}>
        <ButtonContent props={{ type, onClick, label, children }} />
      </a>
    </Link>
  ) : (
    <ButtonContent props={{ type, onClick, label, children, buttonClasses }} />
  );
};

export default Button;
