import { useSelector } from 'react-redux';

import { getReasonableOffer, getPositionInfo } from '../Catalog/helpers';

import { headerSearchResultsMapping } from './HeaderSearchResults';

const HeaderSearchResultList = ({ type, data, onSearchHandler }) => {
  const city = useSelector((state) => state.profile.city);

  const dataWithAvailablePrices = data.map((item) => {
    const { price } = getReasonableOffer({ offers: item.offers, code: city });
    const isPositionAvailable = getPositionInfo(city, item.offers);
    return { ...item, price: isPositionAvailable ? price : null };
  });

  const dataSortedByPrice = dataWithAvailablePrices.sort((a, _) =>
    typeof a === 'number' ? -1 : 1
  );

  return data ? (
    <ul className="header__product-list">
      {dataSortedByPrice.map((item, id) =>
        headerSearchResultsMapping[type](id, onSearchHandler, item)
      )}
    </ul>
  ) : null;
};
const HeaderResults = ({ data, onSearchHandler }) => {
  const isSearchResultsEmpty = !data?.length;

  if (!data) return null;

  return !isSearchResultsEmpty ? (
    <div className="header__results">
      <HeaderSearchResultList type="positions" data={data} onSearchHandler={onSearchHandler} />
    </div>
  ) : (
    <div className="header__results header__results--empty">Ничего не удалось найти</div>
  );
};

export default HeaderResults;
