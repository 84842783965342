import dynamic from 'next/dynamic';
import Link from 'next/link';

import { getPageRoutes } from '../../api';
import { DEFAULT_IMG_URL } from '../../constants';
import { getNames } from '../../utils';
import Price from '../Price';

const ImgWithFallback = dynamic(() => import('../ImgWithFallback'));

const HeaderSearchResultsPositionItem = ({ item, onSearchHandler }) => {
  const { mainName } = getNames(item.change_main_name, item.name, item.name_ru);
  const itemPhotoSrc = item.resize_photo || item.photo || DEFAULT_IMG_URL;

  return (
    <li onClick={onSearchHandler} className="header__product-item product-item">
      <Link href={getPageRoutes.POSITION(item.id)}>
        <a className="">
          <div className="product-item__img">
            <ImgWithFallback photoSrc={itemPhotoSrc} photoWebp={item.webp_photo} alt="" />
          </div>
          <div className="product-item__name-wrapper">
            <p>{mainName}</p>
          </div>
          {item.price ? (
            <div className="product-item__price-wrapper">
              <span className="product-item__price-prefix">от</span>
              <Price classname="product-item__price" price={item.price} />
            </div>
          ) : (
            <div className="product-item__price-wrapper">
              <span className="product-item__price-prefix">Нет в наличии</span>
            </div>
          )}
        </a>
      </Link>
    </li>
  );
};

const HeaderSearchResultsTypeItem = ({ onSearchHandler, item }) => (
  <li onClick={onSearchHandler} className="header__brand-item header__search-item">
    <Link href={getPageRoutes.TYPE(item.id)}>
      <a>
        <span className="header__types-item">{item.name}</span>
      </a>
    </Link>
  </li>
);

const HeaderSearchResultsBrandItem = ({ onSearchHandler, item }) => (
  <li onClick={onSearchHandler} className="header__brand-item header__search-item">
    <Link href={getPageRoutes.BRAND(item.id)}>
      <a>
        <span className="header__brand-text header__brand-text--title">Бренд&nbsp;</span>
        <span className="header__brand-text header__brand-text--name">{item.name}</span>
      </a>
    </Link>
  </li>
);

export const headerSearchResultsMapping = {
  types: (id, onSearchHandler, item) => (
    <HeaderSearchResultsTypeItem key={id} onSearchHandler={onSearchHandler} item={item} />
  ),
  brands: (id, onSearchHandler, item) => (
    <HeaderSearchResultsBrandItem key={id} onSearchHandler={onSearchHandler} item={item} />
  ),
  positions: (id, onSearchHandler, item) => (
    <HeaderSearchResultsPositionItem key={id} onSearchHandler={onSearchHandler} item={item} />
  ),
  items: (id, onSearchHandler, item) => (
    <HeaderSearchResultsPositionItem key={id} onSearchHandler={onSearchHandler} item={item} />
  ),
  categories: (id, onSearchHandler, item) => (
    <HeaderSearchResultsTypeItem key={id} onSearchHandler={onSearchHandler} item={item} />
  ),
};
