import { useEffect, useState, useMemo } from 'react';

import { MOBILE_MAX_SCREEN_WIDTH, TABLET_MAX_SCREEN_WIDTH } from '../constants';
import { debounce } from '../utils/debounce';

export const useClientRender = () => {
  const [screenType, setScreenType] = useState('desktop');
  const [isClient, setIsClient] = useState(false);
  const [windowSize, setWindowSize] = useState({
    screenWidth: undefined,
    screenHeight: undefined,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isScreenType = useMemo(
    () => ({
      desktop: screenType === 'desktop',
      tablet: screenType === 'tablet',
      mobile: screenType === 'mobile',
    }),
    [screenType]
  );

  useEffect(() => {
    if (windowSize.screenWidth) {
      setIsClient(true);
      const screenType =
        windowSize.screenWidth < MOBILE_MAX_SCREEN_WIDTH
          ? 'mobile'
          : windowSize.screenWidth < TABLET_MAX_SCREEN_WIDTH
            ? 'tablet'
            : 'desktop';
      setScreenType(screenType);
    }
  }, [windowSize.screenWidth]);

  return { isClient, screenType, isScreenType };
};
