import cn from 'classnames';
import { useFormik } from 'formik';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { apiRoutes, instantSearch } from '../../api';
import { actions } from '../../redux';

import HeaderFloatButton from './HeaderFloatButton';
import HeaderResults from './HeaderResults';

const Icon = dynamic(() => import('../Icon'));

const HeaderSearch = () => {
  const router = useRouter();
  const [searchTimeout, setSearchTimeout] = useState('');
  const [isSearchFull, setIsSearchFull] = useState(true);
  const [showHeaderResults, setShowHeaderResults] = useState(false);
  const { currentPosition } = useSelector((state) => state.data);
  const seo = useSelector((state) => state.window.seo);
  const city = useSelector((state) => state.profile.city);
  const [data, setData] = useState();
  const isSearchOpen = useSelector((state) => state.window.isSearchOpen);
  const dispatch = useDispatch();

  const { handleSubmit, handleChange, values, setValues, resetForm } = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: yup.object({
      search: yup.string().required(),
    }),
    onSubmit: (values) => {
      resetForm();
      dispatch(actions.setIsSearchOpen(false));
      const query = new URLSearchParams({
        ...values,
        ...(!isSearchFull && { type: currentPosition.value }),
      }).toString();
      router.push(`${apiRoutes.SEARCH}?${query}`);
    },
  });

  const getData = async (value) => {
    const query = new URLSearchParams({ search: value, city, page_size: 8 });
    const response = await instantSearch(query);
    if (response?.data?.results) {
      setData(response?.data.results);
    } else {
      setData();
    }
  };

  const throttledHandler = (value) => {
    if (!value) {
      setData();
      clearTimeout(searchTimeout);
      return;
    }

    clearTimeout(searchTimeout);

    const timeout = setTimeout(() => {
      if (value) {
        getData(value);
      }
    }, 300);

    setSearchTimeout(timeout);
  };

  useEffect(() => {
    throttledHandler(values.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.search]);

  const deleteSearch = () => {
    setValues({ search: '' });
    setIsSearchFull(false);
  };

  useEffect(() => {
    setShowHeaderResults(!!values.search);
  }, [values.search]);

  useEffect(() => {
    const onWindowClickHandler = (e) => {
      if (!e.target.closest('.header__search-form')) {
        setShowHeaderResults(false);
      }
    };

    if (showHeaderResults) {
      window.addEventListener('click', onWindowClickHandler);
    }

    return () => window.removeEventListener('click', onWindowClickHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHeaderResults]);

  const formClassnames = cn('header__search-form', { show: isSearchOpen });

  return (
    <form
      id={seo.url}
      itemType="https://schema.org/WebSite"
      itemScope="itemScope"
      onSubmit={handleSubmit}
      className={formClassnames}
    >
      <HeaderFloatButton
        currentPosition={currentPosition}
        isSearchFull={isSearchFull}
        setIsSearchFull={setIsSearchFull}
      />
      <div
        className="header__search-input-wrapper"
        itemProp="potentialAction"
        itemScope="itemScope"
        itemType="https://schema.org/SearchAction"
      >
        <meta itemProp="url" content={seo.host} />
        <input
          className="header__search-input"
          onChange={handleChange}
          type="search"
          placeholder="Поиск по каталогу"
          name="search"
          value={values.search}
          autoComplete="off"
          itemProp="query-input"
        />
        {showHeaderResults && <HeaderResults data={data} onSearchHandler={deleteSearch} />}
      </div>
      <button className="header__search-button--desktop" type="submit">
        <Icon
          className="header__search-button-icon"
          name="header__search"
          fill="#ffffff"
        />
      </button>
      {values.search ? (
        <Icon
          onClick={deleteSearch}
          className="header__search-delete-icon-tablet header__search-button--tablet"
          name="search__delete-icon"
        />
      ) : (
        <Icon
          className="header__search-button header__search-button--tablet"
          name="header__search"
          fill="#026DB0"
        />
      )}
    </form>
  );
};

export default HeaderSearch;
